import React from "react";
// import "../tailwind.css";
import classNames from "../react-utils/classNames";

export default function Paragraph({ children, ...props }) {
  const fontSize = props.fontSize || "text-base";
  const paragraphStyles =
    props.paragraphStyles || "block font-normal text-gray-500 dark:text-white";

  return <p className={classNames(paragraphStyles, fontSize)}>{children}</p>;
}
