import { useEffect } from "react";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import { setRedirectAppUrl } from "store";

const AppRedirectionWrapper = (props: { children: any }) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const redirectTo = router.query.redirect;

  useEffect(() => {
    (async () => {
      console.log("redirectTo=====>", redirectTo);
      if (redirectTo !== "" && redirectTo) {
        let validApps = process.env.NEXT_PUBLIC_VALID_APP_URLS;
        if (validApps && validApps.length > 0) {
          let currentAppUrl = validApps.includes(redirectTo);
          console.log("currentAppUrl====>", currentAppUrl);
          if (currentAppUrl) {
            dispatch(setRedirectAppUrl(redirectTo));
          } else {
            dispatch(setRedirectAppUrl("/"));
          }
        }
      } else {
        console.log("redirectTo-Empty-dont-do-anything", redirectTo);
        // dispatch(setRedirectAppUrl("/"));
      }
    })();
  }, [redirectTo]);

  return props.children;
};

export default AppRedirectionWrapper;
